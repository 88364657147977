var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('date-picker2',{attrs:{"id":"callTime","overlay":true,"minuteInterval":15,"noWeekendsDays":true,"label":"Best Time to Call","noButtonNow":true,"disabled-hours":[
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '12',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23'
    ],"disabledDates":['2022-12-23', '2022-12-26', '2023-01-02'],"show":_vm.show,"minDate":_vm.dateToday,"maxDate":_vm.maxDate},on:{"close":function($event){_vm.show = false},"input":function($event){return _vm.dateChanged()}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }