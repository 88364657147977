<template>
  <div v-if="showScheduler" class="tcs-modal">
    <div class="scheduler-container" v-show="schedulerContainer">
      <a class="scheduler-exit" id="scheduler-exit" v-on:click="toggleScheduler"
        >&#x2715;</a
      >

      <div id="scheduler-main">
        <h1>Get Your Questions Answered Now!</h1>
        <p>
          Have a question you need answered before taking the next step? Our VIP
          Specialists can help! Schedule a quick call now.
        </p>

        <div id="tcs-error" v-if="errorMessages.length">
          <ul>
            <li v-for="e in errorMessages" v-bind:key="e.id">{{ e }}</li>
          </ul>
        </div>

        <form id="LeadGen" @submit.prevent="processForm">
          <input
            id="FirstName"
            name="FirstName"
            placeholder="First Name"
            maxlength="20"
            type="text"
            v-model="FirstName"
            required
          />

          <input
            id="LastName"
            name="LastName"
            placeholder="Last Name"
            maxlength="30"
            type="text"
            v-model="LastName"
            required
          />

          <input
            name="email"
            maxlength="50"
            placeholder="Email"
            type="text"
            v-model="email"
            id="email"
          />

          <input
            id="PhoneNumber"
            name="PhoneNumber"
            placeholder="Phone Number: (555) 555-5555"
            maxlength="14"
            type="text"
            v-model="PhoneNumber"
            v-on:keyup="formatPhone()"
            required
          />

          <DateTime2 @update:date="dateChanged" />

          <input name="submit" type="submit" value="SUBMIT" class="continue" />

          <p>
            We value your
            <a href="https://manwardpress.com/privacy-policy/" target="_blank"
              >privacy</a
            >. *All time slots are in Eastern Time (ET).
          </p>
        </form>
      </div>
      <div class="scheduler-confirmation">
        <div class="circle-loader">
          <div class="checkmark draw"></div>
        </div>
        <br />
        <p class="confirmation-message"></p>
      </div>
    </div>
  </div>
</template>

<script>
  // import DateTime from "./DatePickerComponent.vue";
  import DateTime2 from "./DatePicker2Component.vue";

  var moment = require("moment-timezone");
  var momentBusiness = require("moment-business-time");

  export default {
    name: "SchedulerModal",
    data: function data() {
      return {
        FirstName: "",
        LastName: "",
        email: "",
        PhoneNumber: "",
        bestTimeToCall: "",
        errorMessages: [],
        moment: moment,
        momentBusiness: momentBusiness,
        schedulerContainer: this.toggleScheduler,
      };
    },
    components: {
      DateTime2,
    },
    props: {
      showScheduler: Boolean,
      toggleScheduler: Function,
    },
    methods: {
      dateChanged: function(value) {
        this.bestTimeToCall = value;
      },

      formatPhone() {
        let val = this.PhoneNumber;
        this.PhoneNumber = val
          .replace(/\D/g, "")
          .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, function(txt, f, s, t) {
            if (t) {
              return `(${f}) ${s}-${t}`;
            } else if (s) {
              return `(${f}) ${s}`;
            } else if (f) {
              return `(${f})`;
            }
          });
      },

      processForm: function(e) {
        let multivariateId = "1049649";
        let notSaveSignup = false;

        let formData = {
          multivariateId: multivariateId,
          notSaveSignup: notSaveSignup,
          firstName: this.FirstName,
          lastName: this.LastName,
          email: this.email,
          phoneNumber: encodeURIComponent(this.PhoneNumber.trim()),
          bestTimeToCall: new Date(this.bestTimeToCall.replace(/-/g, "/")),
          schedulerContainer: this.schedulerContainer,
        };

        this.errorMessages = validateForm(formData);

        if (this.errorMessages.length > 0) {
          e.preventDefault();
        } else {
          submitForm(formData);

          this.FirstName = "";
          this.LastName = "";
          this.email = "";
          this.PhoneNumber = "";
          this.bestTimeToCall = "";
        }
      },
    },
  };

  function validateForm(data) {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let currentDate = new Date();
    let selectedDate = data.bestTimeToCall;
    let messages = [];

    if (!momentBusiness(data.bestTimeToCall).isWorkingTime()) {
      messages.push("Please select a timeslot within business hours");
    }

    if (selectedDate < currentDate) {
      messages.push("Please select a future timeslot.");
    }

    if (data.phoneNumber.length < 10) {
      messages.push("Please enter a valid phone number.");
    }

    if (!data.email.match(validRegex)) {
      messages.push("Please enter a valid email address.");
    }

    return messages;
  }

  function submitForm(data) {
    const currentPageURL = window.location.href;

    const url =
      "https://guqin.ocweb.tools/store?" +
      "&email=" +
      data.email +
      "&firstName=" +
      data.firstName +
      "&lastName=" +
      data.lastName +
      "&phone=" +
      data.phoneNumber +
      "&bestTimeToCall=" +
      moment(data.bestTimeToCall)
        .utcOffset(0, true)
        .toISOString() +
      "&referringURL=" +
      currentPageURL +
      "&promoCode=" +
      getPromoCode(currentPageURL) +
      "&campaignName=" +
      getCampaignName();

    // const url =
    //   "http://127.0.0.1:8000/store?" +
    //   "&email=" +
    //   data.email +
    //   "&firstName=" +
    //   data.firstName +
    //   "&lastName=" +
    //   data.lastName +
    //   "&phone=" +
    //   data.phoneNumber +
    //   "&bestTimeToCall=" +
    //   moment(data.bestTimeToCall)
    //     .utcOffset(0, true)
    //     .toISOString() +
    //   "&referringURL=" +
    //   currentPageURL +
    //   "&promoCode=" +
    //   getPromoCode(currentPageURL) +
    //   "&campaignName=" +
    //   getCampaignName();

    fetch(url, {
      method: "GET",
    })
      .then(data.schedulerContainer)
      .then(showConfirmationMessage(data.bestTimeToCall));
  }

  function getCampaignName() {
    if (JSON.stringify(window.Portrait)) {
      return JSON.stringify(window.Portrait.dataLayer.effort.CampaignName);
    } else {
      return "Not%20available";
    }
  }

  function getPromoCode(currentPageURL) {
    if (currentPageURL.includes("promoCode")) {
      return currentPageURL
        .slice(currentPageURL.search(/\bpromoCode\b/g))
        .slice(10, 18);
    } else if (currentPageURL.includes("pageNumber")) {
      let pageURLArray = currentPageURL.split("/");
      return pageURLArray[4];
    } else {
      return "Not%20available";
    }
  }

  function showConfirmationMessage(datetime) {
    const bestTimeToCall = new Date(datetime);
    const schedulerMain = document.getElementById("scheduler-main");
    const confirmation = document.getElementsByClassName(
      "scheduler-confirmation"
    );
    const circleLoader = document.getElementsByClassName("circle-loader");
    const checkmark = document.getElementsByClassName("checkmark");
    const confirmationMessage = document.getElementsByClassName(
      "confirmation-message"
    );
    const time = bestTimeToCall.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    schedulerMain.style.display = "none";
    confirmation[0].style.display = "block";
    circleLoader[0].classList.toggle("load-complete");
    checkmark[0].style.display = "block";
    confirmationMessage[0].innerHTML =
      "Thank you for scheduling a time with one of our VIP specialists. Get your questions ready! We are excited to help you in any way that we can. Your call is reserved for " +
      getMonthName(bestTimeToCall.getMonth()) +
      " " +
      bestTimeToCall.getDate() +
      " at " +
      time;
  }

  function getMonthName(month) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month];
  }
</script>
