<template>
    <div class="btn-section">
        <span class="call-scheduler-btn"><button v-on:click="toggleScheduler">Schedule a Call &#x260E;</button></span>
        
        <SchedulerModal 
            v-bind:showScheduler="showScheduler"
            v-bind:toggleScheduler="toggleScheduler"
        />
    </div>
</template>

<script>
import SchedulerModal from "./SchedulerModalComponent.vue";

    export default {
        name: 'Button',
        components: {
            SchedulerModal,
        },
        methods: {
            toggleScheduler: function () {
            this.showScheduler = this.showScheduler ? false : true;
            },
        },
          data() {
            return {
                showScheduler: false,
            };
        },
    }
</script>

<style scope>

    /* .btn-section {
        text-align: right;
    } */

    .call-scheduler-btn button {
        border-radius: 5px;
        border: none;
        font-family: source sans, sans-serif;
        font-size: 14px;
        font-weight: bold;

        cursor: pointer;
        padding: 7px 20px 7px 20px;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
        outline: none;
        position: relative;

        background: #136f87;
        color: #fff;
        /* box-shadow: 0 6px #105061; */
        line-height:20px;
        margin-top: 2px;
        margin-bottom: 0px;
        margin-left: 2px;
    }

    .call-scheduler-btn button:after {
        content: '';
        position: absolute;
        z-index: -1;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
    }

    .call-scheduler-btn button:hover {
        /* box-shadow: 0 4px #105061; */

        background: #105061;

        /* top: 2px; */
    }

    .call-scheduler-btn button:active {
        /* box-shadow: 0 0 #105061; */
        background: #105061;
        /* top: 6px; */
    }
</style>
