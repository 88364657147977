<template>
  <div>
    <date-picker2
      id="callTime"
      :overlay="true"
      :minuteInterval="15"
      :noWeekendsDays="true"
      label="Best Time to Call"
      :noButtonNow="true"
      :disabled-hours="[
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '12',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
      ]"
      :disabledDates="['2022-12-23', '2022-12-26', '2023-01-02']"
      :show="show"
      @close="show = false"
      v-model="selectedDate"
      v-on:input="dateChanged()"
      :minDate="dateToday"
      :maxDate="maxDate"
    />
  </div>
</template>

<script>
import DatePicker2 from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  name: "Date2",
  components: {
    DatePicker2
  },

  data() {
    return {
      selectedDate: null,
      show: false,
      dateToday: new Date(new Date().getTime() + -1 * 86400000),
      maxDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000)
    };
  },
  methods: {
    dateChanged() {
      this.$emit("update:date", this.selectedDate);
    },
    getDisableHours() {
      return "";
    }
  }
};
</script>
