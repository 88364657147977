<template>
  <div id="app">
    <Button/>
    <SchedulerModal
       v-bind:showScheduler="showScheduler"
       v-bind:toggleScheduler="toggleScheduler"
    />
  </div>
</template>

<script>
import SchedulerModal from "./components/SchedulerModalComponent.vue";
import Button from "./components/Button.vue"

export default {
  name: "App",
  components: {
    SchedulerModal,
    Button,
  },
  methods: {
    toggleScheduler: function () {
      this.showScheduler = this.showScheduler ? false : true;
    },
  },
  data() {
    return {
      showScheduler: false,
      showButton: false,
      hasAppeared:false
    };
  },
  created() {
    const mouseEvent = () => {
      document.removeEventListener('mouseleave', mouseEvent);
      if (!this.showScheduler) {
        this.showScheduler = true;
        this.hasAppeared = true
      }
    };

    setTimeout(() => {
      document.addEventListener('mouseleave', mouseEvent);
    }, 30000);
  }
};

</script>
